import React from "react";
import "./MiddleCard.css";

import payment from "../../assest/images/payment.png";

const MiddleCardOne = (props) => {
  return (
    <div className="middlecard mt-5">
      <div className="card mx-auto pb-4">
        <div className="cardhead">
          <h4>{props.title}</h4>
        </div>
        <div className="cardmiddle mb-2 mt-2 text-center text-white">
          {props.dec}
        </div>
        <ul className="mb-4">
          <li className="plan me-5 p-1">
            <a href="https://ramp.network/buy/?defaultAsset=BNB" target="_blank" className="w-full text-center">
              Buy BNB with FIAT
            </a>
          </li>
        </ul>
        <div className="px-4 mb-2 text-center">
          <img src={payment} alt="" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default MiddleCardOne;
