import React from "react";
import "./Footer.css";

import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import Logo from "../assest/images/logo.png";

function Footer() {
  return (
    <footer className="footer">
      {/* <div className="container-fluid">
        <div className="row">
          <div
            className="px-0 col-md-12"
            data-aos="fade-up"
            ease-in-sine="ease-in"
            data-aos-delay="100"
            data-aos-duration="600"
          >
            <img src={Img} className="w-100 img-fluid" alt="cartoons" />
          </div>
        </div>
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center footer-head">
              <div className="footer-main-heading">
                <h2 className="py-5 fancy-title">
                  WE ARE NOT HERE TO TAKE PART <br />
                  <span>WE ARE HERE TO TAKE OVER!</span>
                </h2>
              </div>
              <a
                href="https://buy.domain.com/"
                className="mx-auto sub-heading"
                data-aos="fade-up"
                ease-in-sine="ease-in"
                data-aos-delay="100"
                data-aos-duration="600"
              >
                <h3>Join Solerium now!</h3>
              </a>
            </div>
            <div className="flex flex-wrap items-center justify-center pb-3 lg:justify-between border-bottom border-secondary">
              <div
                className="my-4 cuteCat-logo"
                data-aos="fade-up"
                ease-in-sine="ease-in"
                data-aos-duration="600"
              >
                <img src={Logo} alt="Logo" className="logo" />
              </div>
              <div
                className="footer-icons"
                data-aos="fade-up"
                ease-in-sine="ease-in"
                data-aos-delay="100"
                data-aos-duration="600"
              >
                <a
                  href="https://twitter.com/solerium_io"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-80"
                >
                  <TwitterIcon />
                </a>
                <a
                  href="https://t.me/solerium_en"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-80"
                >
                  <TelegramIcon />
                </a>
                <a
                  href="https://solerium.medium.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-80"
                >
                  <svg
                    className="MuiSvgIcon-root"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path d="M180.5,74.262C80.813,74.262,0,155.633,0,256S80.819,437.738,180.5,437.738,361,356.373,361,256,280.191,74.262,180.5,74.262Zm288.25,10.646c-49.845,0-90.245,76.619-90.245,171.095s40.406,171.1,90.251,171.1,90.251-76.619,90.251-171.1H559C559,161.5,518.6,84.908,468.752,84.908Zm139.506,17.821c-17.526,0-31.735,68.628-31.735,153.274s14.2,153.274,31.735,153.274S640,340.631,640,256C640,171.351,625.785,102.729,608.258,102.729Z" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="px-4 py-4 footer-policies lg:px-0">
              <span className="my-3">
                All Rights Reserved to © 2022 Solerium
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
