import React from "react";
import "./TopSection.css";
import Typewriter from "typewriter-effect";
import Satellite from "../assest/images/satelite.png";
import AstronautRocket from "../assest/images/astronaut-rocket.png";

const TopSection = () => {
  return (
    <div className="relative flex flex-col items-center justify-center header">
      <div className="container game_header">
        <div className="flex justify-center">
          <div className="my-8 text-center text-white" data-aos="zoom-in">
            <h1 className="text-center lg:text-8xl text-5xl text-[#fdc91a]">
              <Typewriter
                options={{
                  loop: true,
                  autoStart: true,
                }}
                onInit={(typewriter) => {
                  typewriter.loop = true;
                  typewriter.typeString("Decentralized").deleteAll().start();
                }}
              />
            </h1>
            <h2 className="text-3xl text-center lg:text-5xl">
              Finance Powered by Solerium
            </h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="row d-flex justify-content-center "
          data-aos="zoom-in-up"
        >
          <div className="col-lg-10 ">
            <div className="game-title" data-aos="fade-up">
              <h3>
                Solerium is the leading DeFi &amp;
                <br />
                Play-to-Earn Platform
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="play-container ">
        <div className="play-btn jiggle">
          <a href="https://buy.domain.com">
            <button>Join $SLRM Pre-Sale</button>
          </a>
        </div>
        <div className="whitepaper-btn jiggle">
          <a href="/whitepaper_v1.pdf" className="BuyBTN lg:ml-8 button" target="_blank">
            <span className="slrm">Whitepaper</span>
          </a>
        </div>
      </div>{" "}
      <br />
      <div className="xl:block hidden absolute left-12 top-48 animate-[float-up-down_2s_alternate_infinite_ease-in-out]">
        <img className="h-96" src={Satellite} alt="Astro" />
      </div>
      <div className="xl:block hidden absolute right-16 top-48 animate-[float-up-down_3s_alternate_infinite_ease-in-out]">
        <img className="h-96" src={AstronautRocket} alt="Astro" />
      </div>
      {/* <div className="container Vframe slide-up fade-in-up" data-aos="zoom-in-up"  >
    <div className="row">
     <div className="col-lg-12 d-flex justify-content-center">
      <div className="video">
       <iframe src="https://www.youtube.com/embed/FhzjgQVqwtw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="">
       </iframe>
      </div>
     </div>
    </div>
   </div> */}
    </div>
  );
};

export default TopSection;
