import React from "react";
import "./Tokenomic.css";
import Ninja from "../assest/images/ninja.png";
import Diamond from "../assest/images/diamondIcon.png";
import Coin from "../assest/images/iCoin.png";
import CatIcon from "../assest/images/icon.png";
import Envelop from "../assest/images/envelop.png";
import SquareDiamond from "../assest/images/square-diamond.png";

function Tokenomic() {
  return (
    <div id="tokenomic" className="scroll-mt-64">
      <div
        className="container"
        data-aos="zoom-out"
        data-aos-easing="ease-in-sine"
      >
        <div className="row">
          <div className="col-md-12">
            <div className="tokenomic-heading ">
              <h2>
                IF YOU BELIEVE IN <span>DECENTRALIZED FINANCE</span> THEN YOU
                BELIEVE IN <span>SOLERIUM!</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="pb-24 mt-5 row">
          <div className="flex items-center justify-center col-xl-6 col-lg-12 col-md-12">
            <div
              className="cat-laptop"
              data-aos="fade-right"
              ease-in-sine="ease-in"
            >
              <img src={Ninja} className="Ninja img-fluid max-h-96" alt="" />
            </div>
          </div>

          <div className="col-xl-6 col-lg-12 col-md-12">
            <div
              className="tokenomic-list"
              data-aos="fade-left"
              ease-in-sine="ease-in"
            >
              <div className="tokenBox-head">
                <h3 className="">TOKENOMICS</h3>
              </div>
              <div className="tokenomic-box">
                <div className="tokenomic-textBox">
                  <img src={Diamond} alt="" />
                  <p>
                    Total Supply: <span>1 Billion</span>
                  </p>
                </div>
                <div className="mt-2 tokenomic-textBox">
                  <img src={Coin} alt="" />
                  <p>
                    Type: <span>DeFi &amp; P2E</span>
                  </p>
                </div>
                <div className="mt-2 tokenomic-textBox">
                  <img src={CatIcon} alt="" />
                  <p>
                    Token Symbol: <span>SLRM</span>
                  </p>
                </div>
                <div className="mt-2 tokenomic-textBox">
                  <img src={Envelop} alt="" />
                  <p className="truncate">
                    Contract ID:{" "}
                    <span>
                      <a href="https://bscscan.com/address/0x2edc8ca46eb84fa5bbcdc4226c608f3513b01b49#code">
                        0x2EDC8cA46eB84fa5BbCDC4226c608f3513B01B49
                      </a>
                    </span>
                  </p>
                </div>
                <div className="mt-2 tokenomic-textBox">
                  <img src={SquareDiamond} alt="" />
                  <p>
                    Network: <span>BEP20</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tokenomic;
