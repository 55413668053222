import React from "react";
import "./Stats.css";
import CatBG from "../../assest/images/catBG.png";
import CatIcon from "../../assest/images/icon.png";
import Star from "../../assest/images/Star.png";
import Robot from "../../assest/images/Robot.png";
import PlanetRose from "../../assest/images/Planet-rose.png";
import Blockchain from "../../assest/images/Blockchain.png";
import Rock from "../../assest/images/Rock.png";
import Alien from "../../assest/images/alien.png";

function Stats() {
  return (
    <div>
      <div className="img2"></div>
      <div className="container-fluid sats">
        <div className="row">
          <div
            className="relative flex justify-center pt-16 col-md-6"
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className="xl:block hidden absolute left-12 top-1/3 animate-[float-up-down_1s_alternate_infinite_ease-in-out]">
              <img className="h-24" src={Star} alt="Astro" />
            </div>
            <div className="xl:block hidden absolute right-12 top-12 animate-[float-up-down_1.5s_alternate_infinite_ease-in-out]">
              <img className="h-24" src={PlanetRose} alt="Astro" />
            </div>
            <div className="xl:block hidden absolute right-12 top-2/3 animate-[float-up-down_2s_alternate_infinite_ease-in-out]">
              <img className="h-24" src={Alien} alt="Astro" />
            </div>
            <div className="catBg-img">
              <img src={CatBG} className="h-[40rem] img-fluid" alt="" />
            </div>
          </div>
          <div className="col-md-6 pe-0">
            <div
              className="statsBox"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className="statsBox-text ">
                <div className="mt-5 text-box margin">
                  <img src={Star} alt="Star" className="ms-4 " />
                  <p className="mt-1 mb-2 ms-4">
                    Token name: <span>Solerium</span>
                  </p>
                </div>
                <div className="mt-2 text-box">
                  <img src={Robot} alt="Robot" className="ms-4" />
                  <p className="mt-1 mb-2 ms-4">
                    Token Symbol: <span>$SLRM</span>
                  </p>
                </div>
                <div className="mt-2 text-box">
                  <img src={PlanetRose} alt="Planet" className="ms-4" />
                  <p className="mt-1 mb-2 ms-4">
                    Status: <span>Early Presale</span>
                  </p>
                </div>
                <div className="mt-2 text-box">
                  <img src={CatIcon} alt="" className="ms-4" />
                  <p className="mt-1 mb-2 ms-4">
                    Presale token price: <span>0.02 USD</span>
                  </p>
                </div>
                <div className="mt-2 text-box">
                  <img src={Blockchain} alt="" className="ms-4" />
                  <p className="mt-1 mb-2 ms-4">
                    Blockchain: <span>BSC</span>
                  </p>
                </div>
                <div className="mt-2 text-box">
                  <img src={Alien} alt="" className="ms-4" />
                  <p className="mt-1 mb-2 ms-4">
                    Presale end date: <span>Within 3 months</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="RockImg">
              <img src={Rock} className="mt-5" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats;
