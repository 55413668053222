import React from "react";
import "./SliderTwo.css";
import Carousel from "better-react-carousel";

//import images
import BgImage from "../../assest/images/bg.png";

import CoinMarketCap from "../../assest/images/cmc.webp";
import CryptoCom from "../../assest/images/crypto-com.png";
import Medium from "../../assest/images/medium.webp";
import ReactImg from "../../assest/images/react.webp";

function SliderTwo() {
  return (
    <>
      <div className="slider_two">
        <div className="slider-content">
          <h1 className="pb-4">Meme Partners</h1>
          <Carousel
            cols={4}
            rows={1}
            gap={0}
            loop
            autoplay={2000}
            hideArrow={true}
          >
            <Carousel.Item>
              <a href="https://coinmarketcap.com" target="_blank">
              <img width="70%" src={CoinMarketCap} alt="" />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <a href="https://crypto.com" target="_blank">
                <img width="70%" src={CryptoCom} alt="" />
              </a>
            </Carousel.Item>
            <Carousel.Item>
            <a href="https://medium.com" target="_blank">
              <img width="70%" src={Medium} alt="" className="" />
              </a>
            </Carousel.Item>
            <Carousel.Item>
            <a href="https://reactjs.org/" target="_blank">
              <img
                width="70%"
                src={ReactImg}
                className="h-10 object-contain"
                alt=""
              />
              </a>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <div className="slider_pic">
        <img src={BgImage} alt="" />
      </div>
    </>
  );
}

export default SliderTwo;
